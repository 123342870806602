import styled from 'styled-components';
import { PRIMARY } from '../../utils/colors';

export const ContainerText = styled.div`
  border-radius: 0.6rem;
  background: white;
  padding: 20px;
  position: relative;
  z-index: 100;
  -webkit-box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.1);

  transform: translateX(0px);

  @media screen and (min-width: 992px) {
    transform: translateX(${({ right }) => (right ? '-60px' : '60px')});
  }
`;

export const ContainerTextTitle = styled.h2`
  font-family: 'Roboto', Arial, sans-serif;
  font-weight: bold;
  color: ${PRIMARY};
`;
