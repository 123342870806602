import React, { memo } from 'react';
import { navigate } from 'gatsby';
import {
  Wrapper,
  Button,
  PrimaryTextButton,
  SecondaryTextButton,
} from './styled';

const HowItWorksButton = () => (
  <Wrapper>
    <Button color="primary" size="lg" onClick={() => navigate('/cadastro')}>
      <PrimaryTextButton>Comece grátis</PrimaryTextButton>
      <SecondaryTextButton>Descomplique seu frete</SecondaryTextButton>
    </Button>
  </Wrapper>
);

export default memo(HowItWorksButton);
