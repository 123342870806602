import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Col } from 'reactstrap';
import { ContainerText, ContainerTextTitle } from './styled';

const HowItWorksItemText = ({ text, title, isInverted }) => (
  <Col
    className={classnames('col-12 col-md-6 col-lg-5', {
      'order-12 order-md-1': !isInverted,
    })}
  >
    <ContainerText right={isInverted ? 1 : 0}>
      <ContainerTextTitle>{title}</ContainerTextTitle>
      <p>{text}</p>
    </ContainerText>
  </Col>
);

HowItWorksItemText.propTypes = {
  isInverted: PropTypes.bool,
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

HowItWorksItemText.defaultProps = {
  isInverted: false,
};

export default HowItWorksItemText;
