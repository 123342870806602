import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import HowItWorksItemText from './HowItWorksItemText';

const HowItWorksItem = ({ Image, isInverted, text, title }) => (
  <Row className="row justify-content-center align-items-center mb-5">
    {!isInverted && (
      <>
        <HowItWorksItemText text={text} title={title} />
        <Col className="col-12 col-md-6 order-first order-md-12">
          <Image className="image-fluid" />
        </Col>
      </>
    )}
    {isInverted && (
      <>
        <Col className="col-12 col-md-6">
          <Image className="image-fluid" />
        </Col>
        <HowItWorksItemText text={text} title={title} isInverted={isInverted} />
      </>
    )}
  </Row>
);

HowItWorksItem.propTypes = {
  Image: PropTypes.string.isRequired,
  isInverted: PropTypes.bool,
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

HowItWorksItem.defaultProps = {
  isInverted: false,
};

export default HowItWorksItem;
