import Sistema1 from '../../assets/svgs/sistema1.svg';
import Sistema2 from '../../assets/svgs/sistema2.svg';
import Sistema3 from '../../assets/svgs/sistema3.svg';
import Sistema4 from '../../assets/svgs/sistema4.svg';

export default [
  {
    Image: Sistema1,
    text: `Você preenche o formulário com os seus dados, os dados do destinatário e a descrição da carga. O sistema irá fazer uma cotação com todas as transportadoras disponíveis e listar as opções e valores para você escolher.`,
    title: 'Informe os dados',
  },
  {
    Image: Sistema2,
    text: `Depois da listagem, é só você buscar aquela que melhor se encaixa na sua necessidade e orçamento. Todas as transportadoras são homologadas e passam por constantes avaliações.`,
    title: 'Compare as opções',
  },
  {
    Image: Sistema3,
    text: `Esqueça telefonemas e transações complicadas. A contratação da transportadora e o pagamento pelo serviço são feitos diretamente pelo sistema. As faturas são fechadas semanalmente e você paga todos os fretes contratados de uma vez só.`,
    title: 'Contrate sem complicação',
  },
  {
    Image: Sistema4,
    text: `Por meio do seu login e senha, você consegue gerenciar suas cotações, pagar faturas, rastrear cargas e muito mais. Tudo em um só lugar.`,
    title: 'Gerencie e acompanhe',
  },
];
