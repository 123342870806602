import styled from 'styled-components';
import { Button as RButton } from 'reactstrap';

export const Wrapper = styled.div`
  text-align: center;
`;

export const Button = styled(RButton)`
  line-height: 1.2rem;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  max-width: 255px;
`;

export const PrimaryTextButton = styled.span`
  color: white;
`;

export const SecondaryTextButton = styled.small`
  font-size: 11px;
  display: block;
  font-weight: bold;
  color: #124c52;
`;
